import { navigate } from 'gatsby';
import * as React from 'react';
import { Helmet } from 'react-helmet';
import Layout from '../compontents/Layout/Layout';

type ThanksSpeedPageProps = {
    location: any;
};

const ThanksSpeedPage: React.FC<ThanksSpeedPageProps> = ({ location }) => {
    return (
        <Layout location={location}>
            <Helmet>
                <title>Thanks You | Managed IT Document</title>
                <meta name="robots" content="noindex, nofollow"></meta>
            </Helmet>
            <div className="landing-header py-3" data-testid="thankyou-container">
                <div className={'container center'}>
                    <div className="row">
                        <div className={'col-lg-2 col-md-3 ms-auto'}>
                            <img
                                className={'img-fluid'}
                                style={{ maxHeight: '150px' }}
                                src="../images/landing-pages/squarehook-diamond-white-270.png"></img>
                        </div>
                        <div className={'col-lg-6 col-md-9 my-auto me-auto'}>
                            <h2>Thank you for signing up!</h2>
                            <p>Your free document will be sent to your email!</p>
                        </div>
                    </div>
                </div>
            </div>

            <div className="col-lg-6 col-md-10 mx-auto mb-5">
                <p className="mainText center mt-5 px-3">
                    You may also receive future reports and industry insights from SquareHook, as well as promotional
                    offers and news. You can unsubscribe at any time.
                </p>
                <p className="center">
                    <a className="button-primary" onClick={() => navigate(-1)}>
                        Go Back
                    </a>
                </p>
            </div>
        </Layout>
    );
};

export default ThanksSpeedPage;
